import React from "react"
import Layout from "../components/common/layout"
import Markdown from "react-markdown"
import formazioneStyles from "../styles/pages/formazione.module.scss"
import { graphql } from 'gatsby'

const Formazione = ({ data, location }) => {
  return (
    <Layout
      pageMeta={{
        title: "La mia formazione",
        keywords: [
          "massoterapia",
          "craniosacrale", "reiki" , "psicosomatica",
          "riflessologia plantare",
          "counseling",
        ],
        description:
          "Studio di Massoterapia e Riflessologia Plantare Relazionale.",
        link: "/la-mia-formazione",
      }}
      location={location}> 
      <h1 className="page-title">la mia formazione</h1>
      <ul className={formazioneStyles.list}>
    {data.strapiFormazione.step && data.strapiFormazione.step.map(step => <li key={step.id}><span>{step.anno && step.anno}</span><Markdown>{step.testo}</Markdown></li>)}
      </ul>
    </Layout>
  )
}

export default Formazione

export const pageBlogQuery = graphql`
  query FormazionePageQuery {
    strapiFormazione {
      step {
        id
        testo
        anno
      }
    }
  }
`